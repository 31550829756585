import {
  IFilter,
  FilterSettings,
  IFilterResponseValues,
  FilterOption,
  IFilterResponse,
  IPagination,
  ISortBy,
  IFullFilter,
  FilterType,
  DateTemplate,
  IPreDefinedFilter,
  IDateFilter,
  FilterInputType,
  QueryOptions,
} from './types';
import { CustomFilter } from 'src/@types/customerPortalApi';
import i18n from 'src/i18n';
import { add, sub, set, format, getDate } from 'date-fns';

export const formatDateFrom = (date: Date) => format(set(date, { date: 1 }), 'yyyy-MM-dd');
export const formatDateTo = (date: Date) => {
  const next = add(date, { months: 1 });
  const a = sub(next, { days: getDate(next) });
  return format(a, 'yyyy-MM-dd');
};

export const lastMonthFilter: IDateFilter = {
  date_from: formatDateFrom(sub(new Date(), { months: 1 })),
  date_to: formatDateTo(set(new Date(), { date: 0 })),
  template: DateTemplate.lastMonth,
};

export const getPaginationQuery = (pagination: IPagination): string => {
  const query = Object.keys(pagination).reduce((acc, key) => {
    return `${acc}&${key}=${pagination[key as keyof typeof pagination]}`;
  }, '');

  return query;
};

export const getFilterQuery = (filter: IFilter[], hasDate?: boolean): string => {
  const query = filter?.reduce((acc, item) => {
    if (item.active_values.length && item.query_param) {
      const value = item.active_values.join(',');
      if (!acc && !hasDate) {
        return `${item.query_param.replace(/-./g, (x) => x[1].toUpperCase())}=${value}`;
      }
      return `${acc}&${item.query_param.replace(/-./g, (x) => x[1].toUpperCase())}=${value}`;
    }
    return acc;
  }, '');

  return query;
};

export const getSortByQuery = (sortBy: ISortBy): string => {
  if (!sortBy) return '';

  const { id, order } = sortBy;

  return `&sort_col=${id}&sort_dir=${order}`;
};

export const getDateQuery = (date: IDateFilter, options?: QueryOptions): string => {
  if (!date.date_from || !date.date_to) return '';

  // If camelCase is true, return camelCase query
  if (options?.camelCase) return `dateFrom=${date.date_from}&dateTo=${date.date_to}`;

  return `date_from=${date.date_from}&date_to=${date.date_to}`;
};

export const getFullQuery = (filter: IFullFilter, options?: QueryOptions): string => {
  const { pagination, custom, sortBy, date } = filter;

  const hasDate = date?.date_from && date.date_to ? true : false;
  const sortByQuery = sortBy ? getSortByQuery(sortBy) : '';
  const dateQuery = date ? getDateQuery(date, options) : '';
  const filterQuery = custom ? getFilterQuery(custom, hasDate) : '';
  const paginationQuery = !!pagination ? getPaginationQuery(pagination) : '';

  const query = `${dateQuery}${filterQuery}${paginationQuery}${sortByQuery}`;
  return query;
};

export const getMappedFiltersLegacy = (
  filterSettings: FilterSettings[],
  defaultFilter: CustomFilter[]
): IFilter[] => {
  const mappedFilters = filterSettings.map((item: any) => {
    const filter = defaultFilter.find((filter) => filter.id === item.id);

    if (!filter) {
      return null;
    }

    const values = (filter.values || []).map(
      ({ label, value }: { label?: string; value: string | number }, i) => {
        if (i === item.defaultIndex || value === item.defaultValue) {
          return {
            displayName: item.defaultLabel || label,
            value,
          };
        }

        return {
          displayName: label,
          value,
        };
      }
    );

    const active_values = (() => {
      if (item.defaultIndex !== undefined) {
        return [values[item.defaultIndex].value];
      }

      if (item.defaultValue !== undefined) {
        return [item.defaultValue];
      }

      return [];
    })();

    return {
      ...filter,
      ...item,
      values,
      active_values,
    };
  });
  return mappedFilters;
};

export const getMappedFilters = (
  filterSettings: FilterSettings[],
  defaultFilter: IFilterResponse,
  preDefinedFilters: IPreDefinedFilter[] | null
): IFilter[] => {
  const currentFilter: IFilter[] = [];

  filterSettings.forEach((object, _index) => {
    const settings: FilterSettings = object;

    if (settings.inputType === FilterInputType.Boolean) {
      currentFilter.push({
        values: [],
        name: object?.id ? i18n.t(`filter_label_boolean_${object?.id}`) : 'Filter',
        active_values: preDefinedFilters?.find((pre) => pre.id == object.id)?.activeValues ?? [],
        ...object,
      });
      return;
    }

    let activeFilters: (string | number)[];
    const filter: IFilterResponseValues[] | undefined = defaultFilter.hasOwnProperty(settings?.id)
      ? defaultFilter[settings?.id]
      : undefined;

    if (filter) {
      const valueOptions: FilterOption[] = (
        filter?.map((filterOption: IFilterResponseValues) => {
          if (filterOption) {
            let label = filterOption.name || filterOption.label;

            // Change label if filterType is workplace
            if (
              settings.id == FilterType.Workplaces ||
              settings.id == FilterType.WorkplaceFilters
            ) {
              const address = filterOption?.address ? `(${filterOption?.address})` : '';
              label = `${filterOption?.number} - ${filterOption?.name} ${address}`;
            }

            if (settings.id == FilterType.CustomerFilters) {
              return {
                displayName: label,
                value: filterOption?.number,
              };
            }

            return {
              displayName: label,
              value: filterOption?.id || filterOption?.value,
            };
          }
        }) || []
      ).filter(Boolean) as FilterOption[];

      // Set active filters from preDefined filters
      if (preDefinedFilters) {
        const preDefinedFilter = preDefinedFilters.find((pre) => pre.id == object.id);
        activeFilters = preDefinedFilter?.activeValues ?? [];
        currentFilter.push({
          values: valueOptions,
          name: settings?.id ? i18n.t(`filter_label_${settings?.id}`) : 'Filter',
          active_values: activeFilters ?? [],
          ...settings,
        });
      } else {
        currentFilter.push({
          values: valueOptions,
          name: settings?.id ? i18n.t(`filter_label_${settings?.id}`) : 'Filter',
          active_values: [],
          ...settings,
        });
      }
    }
  });

  return currentFilter;
};

export const getDateSpan = (type: DateTemplate) => {
  const [date_from, date_to] = {
    [DateTemplate.currentMonth]: [
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ],
    [DateTemplate.lastMonth]: [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    ],
    [DateTemplate.lastTwelveMonths]: [
      new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    ],
    [DateTemplate.thisYear]: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    [DateTemplate.custom]: [new Date(), new Date()],
  }[type];

  return { date_from: format(date_from, 'yyyy-MM-dd'), date_to: format(date_to, 'yyyy-MM-dd') };
};

export const getLastYearDateSpan = (dateFilter: IDateFilter): IDateFilter => {
  const { date_from, date_to } = dateFilter;
  const from = new Date(date_from);
  const to = new Date(date_to);

  from.setFullYear(from.getFullYear() - 1);
  to.setFullYear(to.getFullYear() - 1);

  return {
    date_from: formatDateFrom(from),
    date_to: formatDateTo(to),
  };
};
